import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { CommonApi, MedicineApi } from '../../api';
import { ReactComponent as ForwardIcon } from '../../assets/icons/forward.svg';
import type { Medicine, ReferenceItem } from '../../model';
import styles from './DoctorAddMedicineToOrderForm.module.css';
import { Select } from '../atoms';
import { MedicineAutocomplete } from '../molecules';
import { formatMedicinePlural } from '../../utils';

interface Props {
  onAdd(data: OrderMedicine): void;
}

interface TimeWithCount {
  timeId: number;
  count: number;
}  

export interface OrderMedicine {
  totalCount: number,
  count: number;
  frequency: ReferenceItem;
  medicine: Medicine;
  time: TimeWithCount[];
  duration: number;
}



interface FormFields {
  totalCount: number,
  count: number;
  frequencyId: number;
  medicine: Medicine;
  time: TimeWithCount[];
  duration: number;
}

export const DoctorAddMedicineToOrderForm: FC<Props> = ({ onAdd }) => {
  const [totalCount, setTotalCount] = useState<number>(0);

  const { data: timeList = [] } = useQuery({
    queryKey: [CommonApi.KEY, 'getReference', 202],
    queryFn: () => CommonApi.getReference(202),
  });

  const { data: frequencyList = [] } = useQuery({
    queryKey: [CommonApi.KEY, 'getReference', 200],
    queryFn: () => CommonApi.getReference(200),
  });

  const { data: eatingList = [] } = useQuery({
    queryKey: [CommonApi.KEY, 'getReference', 201],
    queryFn: () => CommonApi.getReference(201),
  });

  console.log(eatingList)

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      time: [],
      count: 0,
      duration: 1,
    },
  });

  const selectedTimes = watch('time') || [];

  const updateTotalCount = (times: TimeWithCount[]) => {
    const newTotalCount = times.reduce((acc, time) => acc + time.count, 0);
    setTotalCount(newTotalCount);
  };

  useEffect(() => {
    updateTotalCount(selectedTimes);
  }, [selectedTimes]);

  const handleTimeChange = (timeId: number, isChecked: boolean) => {
    const updatedTimes = isChecked
      ? [...selectedTimes, { timeId, count: 0 }]
      : selectedTimes.filter((time) => time.timeId !== timeId);

    setValue('time', updatedTimes);
  };

  const handleTimeCountChange = (index: number, count: number) => {
    const updatedTimes = [...selectedTimes];
    updatedTimes[index].count = count; 
    setValue('time', updatedTimes); 
    updateTotalCount(updatedTimes); 
  };

  const handleEatingChange = (eatingId: string, index: number) => {
    const selectedEating = eatingList.find((item) => item.id === Number(eatingId));
    if (selectedEating) {
      const newCount = Number(selectedEating.value);
      const updatedTimes = selectedTimes.map((time, i) =>
        i === index ? { ...time, count: newCount } : time
      );
      setValue('time', updatedTimes);
    }
  };
  const onSubmit: SubmitHandler<FormFields> = useCallback(
    (data) => {
      console.log('Данные формы:', data);
      if (!data.medicine || !data.frequencyId || data.time.length === 0) {
        console.error('Не все обязательные поля заполнены');
        return;
      }
      onAdd({
        totalCount: totalCount, 
        count: data.time[0].count, 
        frequency: frequencyList.find((item) => item.id === data.frequencyId)!,
        medicine: data.medicine,
        time: data.time.map((time) => ({
          ...timeList.find((t) => t.id === time.timeId)!,
          timeId: time.timeId,
          count: time.count, 
        })),
        duration: data.duration,
      });
    },
    [frequencyList, eatingList, onAdd, timeList, totalCount],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-[24px]">
        <Controller
          control={control}
          name="medicine"
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <MedicineAutocomplete
              className="mb-[12px]"
              error={fieldState.error}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <Controller
          control={control}
          name="frequencyId"
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => {
            return (
              <Select
                className="mb-[24px]"
                error={fieldState.error}
                onChange={field.onChange}
                options={frequencyList.map((frequency) => ({
                  label: frequency.value,
                  value: frequency.id,
                }))}
                placeholder="Частота приема"
                value={field.value}
              />
            );
          }}
        />

        <div className="mb-[24px]">
          <span className="block mb-[12px] text-[14px]">Когда принимать</span>
          <div className="flex gap-[12px]">
            {timeList.map((time) => (
              <label className={styles.radio} key={time.id}>
                <input
                  className="hidden"
                  type="checkbox"
                  checked={selectedTimes.some((t) => t.timeId === time.id)}
                  onChange={(e) => handleTimeChange(time.id, e.target.checked)}
                />
                <span className="whitespace-nowrap leading-normal">{time.value}</span>
              </label>
            ))}
          </div>

          {selectedTimes.map((time, index) => (
            <div key={time.timeId}>
              <div className="mt-[12px]">
                <span className="block mb-[12px] text-[14px]">
                Сколько таблеток принимать за один прием
                </span>
                <input
                  {...register(`time.${index}.count`, {
                    required: true,
                    valueAsNumber: true,
                    min: 1,
                  })}
                  className={classNames('mb-[12px] input w-[130px]', errors.time?.[index]?.count && 'input--invalid')}
                  step={1}
                  type="number"
                  onChange={(e) => handleTimeCountChange(index, Number(e.target.value))}
                />
              </div>

              <div className="flex flex-wrap gap-[6px]">
                {eatingList.map((eating) => (
                  <label className={styles.radio} key={eating.id}>
                    <input
                      className='hidden'
                      type="radio"
                      name={`eating-${index}`}
                      value={eating.id}
                      onChange={(e) => handleEatingChange(e.target.value, index)}
                    />
                    <span className="whitespace-nowrap leading-normal">
                      {eating.value}&nbsp;{formatMedicinePlural(Number(eating.value))}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-[24px]">
          <span className="block mb-[12px] text-[14px]">Продолжительность приема (в днях)</span>
          <input
            type="number"
            {...register('duration', {
              required: true,
              valueAsNumber: true,
              min: 1,
            })}
            className={classNames('mb-[12px] input w-[130px]', errors.duration && 'input--invalid')}
            min={1}
          />
        </div>
      </div>
      <footer className="p-[24px]">
        <button
          className="button button--primary w-full flex items-center justify-between px-2 text-left"
          type="submit"
        >
          Добавить лекарство
          <ForwardIcon />
        </button>
      </footer>
    </form>
  );
};