import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { MedicineApi } from '../api';
import { MedicineDetails, Modal, PrivateRoute } from '../components';
import { RoutePath } from '../config';
import { DoctorInnerPageLayout } from '../layouts';
import { formatMoney } from '../utils';
import type { Medicine } from '../model';

const CHARACTERS = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЭЮЯ';

export const DoctorMedicinePage: FC = () => {
  const [medicine, setMedicine] = useState<Medicine>();
  const [searchText, setSearchText] = useState('');
  const [activeCharacter, setActiveCharacter] = useState('');
  const { data: medicineList = [], isLoading } = useQuery<Medicine[]>({
    queryFn: () => MedicineApi.getAll(),
    queryKey: [MedicineApi.KEY, 'getAll'],
  });

  const filteredMedicines = medicineList.filter((medicine) => {
    const matchesSearchText = medicine.name.toLowerCase().includes(searchText.toLowerCase());
    const matchesActiveCharacter =
      !activeCharacter || medicine.name[0].toLowerCase() === activeCharacter.toLowerCase();
    return matchesSearchText && matchesActiveCharacter;
  });

  return (
    <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
      <DoctorInnerPageLayout>
        <Modal isOpen={Boolean(medicine)} onClose={() => setMedicine(undefined)}>
          {!!medicine && <MedicineDetails medicine={medicine} />}
        </Modal>
        <div className="bg-brand-6 rounded-lg">
          <header className="p-[24px]">
            <input
              className="input input--search"
              onChange={(event) => setSearchText(event.target.value)}
              placeholder="Аспирин, Байер, грипп..."
              type="text"
              value={searchText}
            />
          </header>
          <div className="bg-white rounded-lg">
            <span className="px-[24px] pt-[24px] block text-[12px] text-brand-1/50">поиск препаратов по алфавиту</span>
            <div className="mt-[8px] pb-[24px] flex gap-[8px] px-[24px]">
              {CHARACTERS.split('').map((character) => (
                <button
                  className={classNames('text-[18px] leading-none transition-all hover:text-brand-1 border-b', {
                    'border-transparent': character !== activeCharacter,
                    'text-brand-1/30': activeCharacter !== '' && character !== activeCharacter,
                    'border-brand-1': character === activeCharacter,
                  })}
                  onClick={() => setActiveCharacter(character)}
                  key={character}
                  type="button"
                >
                  {character}
                </button>
              ))}
            </div>
            <div className="pt-[16px] px-[24px] pb-[24px] border-t border-dashed border-brand-1/50">
              <span className="block">Найдено препаратов:&nbsp;{filteredMedicines.length}</span>
              {filteredMedicines.length > 0 && (
                <table className="mt-[24px] table">
                  <thead>
                    <tr>
                      <th className="text-left">Название</th>
                      <th className="text-left">Форма выпуска</th>
                      <th className="text-left">Состав</th>
                      <th className="text-right">Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredMedicines.map((medicine) => (
                      <tr key={medicine.id}>
                        <td>
                          <div
                            className="flex items-center gap-2 text-left font-bold cursor-pointer"
                            onClick={() => setMedicine(medicine)}
                          >
                            {medicine.picture?.image && (
                              <img
                                alt={medicine.name}
                                height={44}
                                width={44}
                                src={medicine.picture.image}
                              />
                            )}
                            {medicine.name}
                          </div>
                        </td>
                        <td className="text-left">
                          {medicine.form_type.value}&nbsp;массой&nbsp;{medicine.dosage}&nbsp;
                          {medicine.dosage_type.value}
                        </td>
                        <td>
                          <div className="flex gap-x-[6px] gap-y-[4px]">
                            {medicine.components.map((component) => (
                              <span
                                className="px-[6px] leading-[22px] rounded-[6px] border border-brand-3 text-[12px] text-black/70"
                                key={component.component.id}
                              >
                                {component.component.name}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td className="text-right">{formatMoney(medicine.medicine_price)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </DoctorInnerPageLayout>
    </PrivateRoute>
  );
};