import { Combobox, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, Fragment, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { MedicineApi } from '../../api';
import type { Medicine } from '../../model';

interface Props {
  className?: string;
  error?: FieldError;
  onChange(newValue: Medicine): void;
  value: Medicine | null;
}

export const MedicineAutocomplete: FC<Props> = ({ className, error, onChange, value }) => {
  const [query, setQuery] = useState('');
  const startsWith = useMemo(() => query.charAt(0) || 'Т', [query]);
  const {
    data: medicineList = [],
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: [MedicineApi.KEY, 'getAll', startsWith, query],
    queryFn: () => MedicineApi.getAll(startsWith, query),
  });

  return (
    <Combobox value={value} onChange={onChange}>
      <div className={classNames('relative', className)}>
        <Combobox.Input
          className={classNames('input input--block bg-white text-left', {
            'input--invalid': Boolean(error),
          })}
          displayValue={(medicine: Medicine) => (medicine ? medicine.name : '')}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Лекарство"
        />
        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-[300px] w-full overflow-auto rounded-md bg-white shadow-lg border border-brand-2">
            {isLoading && (
              <Combobox.Option disabled className="relative cursor-pointer select-none p-2 hover:bg-brand-6" value="">
                Загрузка...
              </Combobox.Option>
            )}
            {isSuccess && medicineList.length === 0 && (
              <Combobox.Option disabled className="relative cursor-pointer select-none p-2 hover:bg-brand-6" value="">
                Ничего не найдено
              </Combobox.Option>
            )}
            {medicineList.map((medicine) => (
              <Combobox.Option
                key={medicine.id}
                className="relative cursor-pointer select-none p-2 hover:bg-brand-6"
                value={medicine}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={classNames('block truncate', {
                        'font-bold': selected,
                      })}
                    >
                      {medicine.name}
                    </span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
