import ruLocale from '@fullcalendar/core/locales/ru';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import { useQuery } from '@tanstack/react-query';
import add from 'date-fns/add';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import { FC, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ReceiptApi } from '../api';
import { PrivateRoute } from '../components';
import { ClientInnerPageLayout } from '../layouts';
import { capitalize, formatDateRu, formatDurationRu, formatMedicinePlural } from '../utils';
import styles from './ClientCalendarPage.module.css';

const COLORS = ['#F0DFBA', '#BFC0E0', '#BDE6DD'];

export const ClientCalendarPage: FC = () => {
  const [searchParams] = useSearchParams();
  const calendarRef = useRef<FullCalendar>(null);
  const [start, setStart] = useState<Date | undefined>();
  const [end, setEnd] = useState<Date>();
  useQuery({
    queryKey: [ReceiptApi.KEY, 'get', start, end],
    queryFn: () =>
      ReceiptApi.get({
        date_from: start ? format(start, 'dd-MM-yyyy') : undefined,
        date_to: end ? format(end, 'dd-MM-yyyy') : undefined,
      }),
    onSuccess(receipts) {
      if (!calendarRef.current) {
        return;
      }
      const calendarApi = calendarRef.current.getApi();

      for (const event of calendarApi.getEvents()) {
        event.remove();
      }

      for (const receipt of receipts) {
        let index = 0;
        for (const { amount, daytime, duration, eating, frequency, medicine } of receipt.receipt_medicine) {
          calendarApi.addEvent({
            color: COLORS[index++],
            title: `${medicine.name} • ${daytime.value} • ${eating.value} ${formatMedicinePlural(
              Number(eating.value),
            )} ${frequency.value}`,
            start: receipt.start_date,
            end: add(receipt.start_date, {
              days: duration,
            }),
            extendedProps: {
              duration,
            },
          });
        }
      }
    },
  });

  return (
    <PrivateRoute>
      <ClientInnerPageLayout>
        <FullCalendar
          headerToolbar={{
            start: 'title',
            center: '',
            end: 'prev today next',
          }}
          ref={calendarRef}
          initialDate={searchParams.get('startDate') ?? undefined}
          initialView="dayGridMonth"
          locale="ru"
          locales={[ruLocale]}
          plugins={[dayGridPlugin]}
          eventDidMount={(arg) => {
            arg.el.setAttribute('data-tooltip-id', 'my-tooltip');
            arg.el.setAttribute(
              'data-tooltip-content',
              `${arg.event.title} • На протяжении ${formatDurationRu({
                days: arg.event.extendedProps.duration,
              })}`,
            );
          }}
          datesSet={(dates) => {
            if (start === undefined || !isEqual(start, dates.start)) {
              setStart(dates.start);
            }
            if (end === undefined || !isEqual(end, dates.end)) {
              setEnd(dates.end);
            }
          }}
          titleFormat={(data) => `${capitalize(formatDateRu(data.date.marker, 'LLLL yyyy'))}`}
        />
        <Tooltip noArrow className={styles.tooltip} id="my-tooltip" />
      </ClientInnerPageLayout>
    </PrivateRoute>
  );
};
