import { FC, useCallback, useState } from 'react';
import { PrivateRoute } from '../components';
import { ClientInnerPageLayout } from '../layouts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useProfile } from '../hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PeopleApi, UserDTO } from '../api';
import classNames from 'classnames';

export const ClientSettingsPage: FC = () => {
  const [tab, setTab] = useState<'settings' | 'cards'>('settings');
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: PeopleApi.updateMe,
    onSuccess() {
      queryClient.refetchQueries([PeopleApi.KEY, 'me']);
    },
  });
  const user = useProfile(true);
  const { handleSubmit, register } = useForm<UserDTO>({
    defaultValues: {
      address_text: user?.address?.[0].address_text ?? '',
      midname: user?.midname,
      name: user?.name,
      surname: user?.surname,
    },
  });

  const onSubmit: SubmitHandler<UserDTO> = useCallback(
    (data) => {
      mutate(data);
    },
    [mutate],
  );

  return (
    <PrivateRoute>
      <ClientInnerPageLayout>
        <div className="mb-[36px] flex gap-[8px]">
          <button
            className={classNames('py-[4px] px-[16px] rounded-sm leading-normal border border-white transition-all', {
              'bg-white': tab === 'settings',
            })}
            onClick={() => setTab('settings')}
            type="button"
          >
            Личные данные
          </button>
          <button
            className={classNames('py-[4px] px-[16px] rounded-sm leading-normal border border-white transition-all', {
              'bg-white': tab === 'cards',
            })}
            onClick={() => setTab('cards')}
            type="button"
          >
            Методы оплаты
          </button>
        </div>

        {tab === 'settings' && (
          <div className="grid lg:grid-cols-2 gap-[36px]">
            <div className="bg-white rounded-lg overflow-hidden text-brand-1">
              <header className="py-[16px] px-[24px] bg-brand-6 rounded-t-lg">
                <h2 className="text-[20px] leading-normal font-bold">Личные данные</h2>
              </header>
              <form className="grid gap-1 p-2" onSubmit={handleSubmit(onSubmit)}>
                <input {...register('name')} className="input" name="name" placeholder="Имя" type="text" />
                <input {...register('surname')} className="input" name="surname" placeholder="Фамилия" type="text" />
                <input {...register('midname')} className="input" name="midname" placeholder="Отчество" type="text" />
                <input
                  {...register('address_text')}
                  className="input"
                  name="address_text"
                  placeholder="Адрес"
                  type="text"
                />
                <button className="button button--block button--primary" type="submit">
                  Сохранить
                </button>
              </form>
            </div>
          </div>
        )}

        {tab === 'cards' && (
          <div className="grid lg:grid-cols-2 gap-[36px]">
            {user?.cards.map((card) => (
              <div className="bg-white rounded-lg overflow-hidden text-brand-1">
                <header className="py-[16px] px-[24px] bg-brand-6 rounded-t-lg">
                  <h2 className="text-[20px] leading-normal font-bold">Карта</h2>
                </header>
                <div className="p-2">
                  <div className="flex justify-between p-1 border border-brand-1/50 rounded-sm">
                    {card.card_number_masked}
                    <span>
                      {card.card_month_end.toString().padStart(2, '0')}/20{card.card_year_end}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </ClientInnerPageLayout>
    </PrivateRoute>
  );
};
