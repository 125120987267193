import type { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ClientsIcon } from '../assets/icons/clients.svg';
import { ReactComponent as CreateOrderIcon } from '../assets/icons/create_order.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg';
import { ReactComponent as MedicinesIcon } from '../assets/icons/medicines.svg';
import { ReactComponent as OrderHistoryIcon } from '../assets/icons/order_history.svg';
import { Logo } from '../components';
import { RoutePath } from '../config';
import { useAuthContext, useProfile } from '../hooks';
import styles from './ClientInnerPageLayout.module.css';

export const DoctorInnerPageLayout: FC<PropsWithChildren> = ({ children }) => {
  const profile = useProfile();
  const { signOut } = useAuthContext();

  return (
    <div className="grid grid-cols-[200px_1fr] p-[12px] gap-2 min-w-[1280px]">
      <aside className="flex flex-col gap-[22px] pl-[8px]">
        <header className="mt-[28px] ml-[16px]">
          <Logo />
        </header>
        <div className="flex-1">
          <div className="grid gap-[4px] whitespace-nowrap">
            <NavLink className={styles.item} to={RoutePath.DoctorCreateOrder}>
              <CreateOrderIcon />
              Собрать заказ
            </NavLink>
            <NavLink className={styles.item} to={RoutePath.DoctorPatients}>
              <ClientsIcon />
              Пациенты
            </NavLink>
            <NavLink className={styles.item} to={RoutePath.DoctorOrderHistory}>
              <OrderHistoryIcon />
              Заказы и платежи
            </NavLink>
            <NavLink className={styles.item} to={RoutePath.DoctorMedicine}>
              <MedicinesIcon />
              Препараты
            </NavLink>
          </div>
        </div>
        <footer className="pb-2">
          <span className={styles.item}>
            <img alt="Avatar" height="40" width="40" src={require('../assets/images/avatar.png')} />
            {profile?.name}
          </span>
          <hr className="my-[13px] border-brand-1/20" />
          <button
            className={styles.item}
            onClick={() => {
              signOut();
            }}
            type="button"
          >
            <LogoutIcon />
            Выйти
          </button>
        </footer>
      </aside>
      <div className="p-[36px] min-h-[calc(100vh-24px)] bg-brand-3 rounded-lg">{children}</div>
    </div>
  );
};
