import type { FC } from 'react';
import { OrderItem } from '../../model/OrderItem';
import { formatMoney } from '../../utils';

interface Props {
  index: number;
  item: OrderItem;
}

export const OrderItemRow: FC<Props> = ({ index, item }) => {
  const { amount, medicine, price, receipt_medicine } = item;

  return (
    <tr>
      <td className="text-brand-1/30 border-none">{index + 1}</td>
      <td>
        <span className="leading-normal">
          <strong>{medicine.name}</strong>&nbsp;{amount}шт
        </span>
        <span className="block text-brand-1/30 text-[14px] leading-[20px]">
          x{receipt_medicine?.eating.value}&nbsp;•&nbsp;{receipt_medicine?.daytime.value}
        </span>
      </td>
      <td className="text-right">{formatMoney(price)}</td>
      <td />
    </tr>
  );
};
